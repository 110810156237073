<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!--snackbar-->
        <v-snackbar
          v-model="snackbar"
          :light="$vuetify.theme.dark"
          :timeout="10000"
          transition="scroll-y-reverse-transition"
          top
        >
          Please wait as your request is being submitted
          <template #action="{ attrs }">
            <v-btn
              color="error"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>

        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Forgot Password? 🔒
          </p>
          <p class="mb-2">
            Enter your email and we'll send you instructions to reset your password
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="forgetpasswordForm"
            @submit.prevent="handleFormSubmit"
          >
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              placeholder="example@kepsa.or.ke"
              :error-messages="errorMessages.email"
              :rules="[validators.required, validators.emailValidator]"
              hide-details="auto"
              class="mb-4"
            ></v-text-field>

            <v-btn
              block
              color="primary"
              type="submit"
            >
              Send reset link
            </v-btn>
          </v-form>

          <!-- divider -->
          <v-card-text class="d-flex align-center mt-2">
            <v-divider></v-divider>
            <span class="mx-5">or</span>
            <v-divider></v-divider>
          </v-card-text>

          <!-- social links -->
          <v-card-actions class="d-flex justify-center">
            <v-btn
              v-for="link in socialLink"
              :key="link.icon"
              icon
              class="ms-1"
            >
              <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
                {{ link.icon }}
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card-text>

        <v-card-actions class="d-flex justify-center align-center">
          <router-link
            :to="{name:'auth-login'}"
            class="d-flex align-center text-sm"
          >
            <v-icon
              size="24"
              color="primary"
            >
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Back to login</span>
          </router-link>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    />

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
import { mdiChevronLeft, mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import { useRouter } from '@core/utils'

import themeConfig from '@themeConfig'
import axios from 'axios'

export default {
  setup() {
    const forgetpasswordForm = ref(null)
    const snackbar = ref(false)
    const email = ref('')
    const errorMessages = ref([])
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    const handleFormSubmit = () => {
      const isFormValid = forgetpasswordForm.value.validate()

      if (!isFormValid) return
      snackbar.value = true
      axios
        .post(
          `${vm.$config.chmApp.baseUrl}/api/v1/auth/password/forgot`,
          { email: email.value },
          { headers: { 'Content-Type': 'application/json' } },
        )
        .then(response => {
          router.push('/')
          return response
        })
        .catch(error => {
          errorMessages.value = { email: error.response.data['message'] }
        })
    }

    return {
      snackbar,
      email,
      socialLink,
      forgetpasswordForm,
      handleFormSubmit,
      errorMessages,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
      },
      validators: {
        required,
        emailValidator,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
